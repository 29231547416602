import React from "react";
import { Container, Menu } from "semantic-ui-react";
import { BadRequestError } from "../js/errors.js";
import MainPanel from "./mainPanel";
import "../styles/App.css";

/**
 * A component which is rendered for loading.
 */
export const LoadingComponent = () => {
  return (
    <div className="ui active dimmer">
      <div className="ui large text loader">
        <h4>Loading</h4>
      </div>
    </div>
  );
};

/**
 * A component which will display the http status code and error message.
 */
export const ErrorComponent = (props) => {
  return (
    <Container style={{ marginTop: "8em", marginBottom: "8em" }}>
      <div className="error-code"> {props.error.status ? props.error.status : "Oops!"} </div>
      <div className="error-type">{props.error.type}</div>
      <div className="error-message">{props.error.message}</div>
    </Container>
  );
};

/**
 * A shared page which all the pages inherit from, containing a menu and a footer.
 */
const BasePage = (props) => {
  return (
    <div className="app">
      <Menu size="massive" color="blue" fixed="top" inverted />
      {props.children}
    </div>
  );
};

/**
 * The home page for this website.
 */
export const HomePage = (props) => {
  return (
    <BasePage>
      <MainPanel
        listId={props.match.params.listId}
        userInternalId={props.match.params.userInternalId}
        notificationId={props.match.params.notificationId}
      />
    </BasePage>
  );
};

/**
 * This page is rendered if the URL does not fit into the pattern of:
 * https://{domain}/uw/:listId/:userInternalId/:notificationId
 */
export const BadRequestPage = (props) => {
  return (
    <BasePage>
      <ErrorComponent error={BadRequestError} />
    </BasePage>
  );
};
