import React from "react";
import { Message, Icon } from "semantic-ui-react";

/**
 * A message which will be rendered after end-user clicks the Update button.
 */
export const UpdateSuccessMessage = () => {
  return (
    <Message success icon>
      <Icon name="check circle outline" />
      <Message.Content>
        <Message.Header as="h3">Subscription preferences updated</Message.Header>
        You've successfully updated your subscription preferences.
      </Message.Content>
    </Message>
  );
};
