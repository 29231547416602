import React from "react";
import { Checkbox, Header } from "semantic-ui-react";

/**
 * A topic preference component, which includes a checkbox, topic name,
 * and topic description.
 */
const TopicPreference = (props) => {
  return (
    <Checkbox
      id={props.id}
      data-testid="topicCheckbox"
      label={{
        children: (
          <Header as="h5">
            {props.displayName}
            <Header.Subheader style={{ marginTop: "0.5em" }}>
              {props.description ? props.description : <span>&nbsp;&nbsp;</span>}
            </Header.Subheader>
          </Header>
        ),
      }}
      checked={props.subscribed}
      onChange={props.onChange}
    />
  );
};

export default TopicPreference;
