import { onFulfilled, onRejected } from "./responseHelper.js";

/**
 * Gets an end-user's topic preferences with respect to the list.
 *
 * @param {string} listId - The internal list Id (UUID)
 * @param {string} userInternalId - The internal user Id (UUID)
 * @param {string} notificationId - The notification Id
 */
export function getTopicPreference(listId, userInternalId, notificationId) {
  const url = getApiGatewayUrl(listId, userInternalId, notificationId);
  const response = fetch(url, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    headers: { Origin: window.location.origin },
  });
  return response.then(onFulfilled, onRejected);
}

/**
 * Updates an end-user's topic preferences with respect to the list.
 *
 * @param {string} listId - The internal list Id (UUID)
 * @param {string} userInternalId - The internal user Id (UUID)
 * @param {string} notificationId - The notification Id
 * @param {boolean} unsubscribed - Updated unsubscribed flag
 * @param {Array.<Object>} topicPreferences - Updated topic preferences
 */
export function updateTopicPreference(listId, userInternalId, notificationId, unsubscribed, topicPreferences) {
  const url = getApiGatewayUrl(listId, userInternalId, notificationId);
  const response = fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    headers: {
      "Origin": window.location.origin,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      Unsubscribed: unsubscribed,
      TopicPreferences: topicPreferences,
    }),
  });
  return response.then(onFulfilled, onRejected);
}

/**
 * Gets the API Gateway REST URL.
 *
 * @param {string} listId - The internal list Id (UUID)
 * @param {string} userInternalId - The internal user Id (UUID)
 * @param {string} notificationId - The notification Id
 */
export function getApiGatewayUrl(listId, userInternalId, notificationId) {
  const host = window.location.hostname;
  return `https://o.${host}/users/${listId}/${userInternalId}/${notificationId}`;
}
