import React from "react";
import { Route, Switch } from "react-router-dom";
import { HomePage, BadRequestPage } from "../components/pages.jsx";

const Routes = () => {
  return (
    <Switch>
      <Route exact path="/uw/:listId/:userInternalId/:notificationId" component={HomePage} />
      <Route path="/" component={BadRequestPage} />
    </Switch>
  );
};

export default Routes;
