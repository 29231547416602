export const BadRequestError = {
  status: 400,
  type: "HTTP 400 Bad Request",
  message: "The browser sent a request that this server could not understand.",
};

export const ForbiddenError = {
  status: 403,
  type: "HTTP 403 Forbidden",
  message: "The request is forbidden. You do not have access to this page.",
};

export const NotFoundError = {
  status: 404,
  type: "HTTP 404 Not Found",
  message: "This page isn't available. Please try searching for something else.",
};

export const InternalServerError = {
  status: 500,
  type: "HTTP 500 Internal Server Error",
  message:
    "The server encountered an internal error and was unable to complete your request. You might be able to resolve this issue by trying your request again.",
};

export const NetworkError = {
  type: "NetworkError",
  message: "The request is rejected. Please try again.",
};

export const UnknownError = {
  type: "UnknownError",
  message: "An unknown error has occurred. Please try again.",
};
