import React from "react";
import TopicPreferences from "./topicPreferences";
import { Header } from "semantic-ui-react";

/**
 * The container showing end-user's subscription statuses.
 */
const SubscriptionInfo = (props) => {
  // Shows nothing if there is no topic in the list.
  if (!Array.isArray(props.prefs) || !props.prefs.length) {
    return null;
  }

  return (
    <React.Fragment>
      <Header as="h4">
        Choose which types of email communications you'd like to receive from us. Once you've made your selection, click
        Update to confirm your subscription preferences.
      </Header>
      <TopicPreferences prefs={props.prefs} onChange={props.onChange} />
    </React.Fragment>
  );
};

export default SubscriptionInfo;
