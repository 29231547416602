import React from "react";
import TopicPreference from "./topicPreference";
import { Item } from "semantic-ui-react";

/**
 * An array of topic preference components.
 */
const TopicPreferences = (props) => {
  return (
    <Item.Group relaxed="very">
      {props.prefs.map((pref) => (
        <Item key={pref.id}>
          <TopicPreference key={pref.id} onChange={props.onChange} {...pref} />
        </Item>
      ))}
    </Item.Group>
  );
};

export default TopicPreferences;
