import {
  BadRequestError,
  ForbiddenError,
  InternalServerError,
  NetworkError,
  NotFoundError,
  UnknownError,
} from "./errors.js";

/**
 * This function is a handler for a successful fetch() API call,
 * which handles different types of HTTP response.
 */
export function onFulfilled(response) {
  switch (response.status) {
    case 200:
      return response.json();
    case 400:
      return Promise.reject(BadRequestError);
    case 403:
      return Promise.reject(ForbiddenError);
    case 404:
      return Promise.reject(NotFoundError);
    case 500:
      return Promise.reject(InternalServerError);
    default:
      return Promise.reject(UnknownError);
  }
}

/**
 * This function is a handler for a rejected fetch() API call, mostly because of
 * an internet issue. Thus, the response would not contain the HTTP status code.
 */
export function onRejected(response) {
  if (!response.status) {
    return Promise.reject(NetworkError);
  }
}
