/**
 * Converts an array of topic preferences to a map.
 *
 * @param {Array.<Object>} topicPreferences - An end-user's topic preferences returned by the GET API, nullable
 */
export function buildTopicPreferenceMap(topicPreferences) {
  let topicPreferenceMap = {};
  if (Array.isArray(topicPreferences) && topicPreferences.length) {
    topicPreferences.forEach((pref) => {
      topicPreferenceMap[pref["TopicName"]] = pref["SubscriptionStatus"];
    });
  }
  return topicPreferenceMap;
}

/**
 * Check if the topic is subscribed to by the end-user or by default.
 *
 * @param {Object} topic - A single topic object
 * @param {Object} topicPreferenceMap - The topic preferences explicitly set by the end-user
 * @param {boolean} unsubscribeAll - The unsubscribed flag returned by the GET API
 */
export function isSubscribed(topic, topicPreferenceMap, unsubscribeAll) {
  const subscriptionStatus =
    topic["Name"] in topicPreferenceMap ? topicPreferenceMap[topic["Name"]] : topic["DefaultSubscriptionStatus"];
  return !unsubscribeAll && subscriptionStatus === "OPT_IN";
}
