import React, { Component } from "react";
import SubscriptionInfo from "./subscriptionInfo";
import { ErrorComponent, LoadingComponent } from "./pages";
import { UpdateSuccessMessage } from "./messages";
import { Button, Checkbox, Divider, Grid, Header } from "semantic-ui-react";
import { getTopicPreference, updateTopicPreference } from "../js/topicApis.js";
import { buildTopicPreferenceMap, isSubscribed } from "../js/dataHelper.js";
import "../styles/App.css";

class MainPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      prefs: [],
      userId: "",
      unsubscribeAll: false,
      updateCompleted: false,
      updateButtonDisabled: true,
    };
  }

  /**
   * This method is invoked after the initial render. If the setState() method is called within this method,
   * an extra rendering would be triggered, but it will happen before the browser updates the screen.
   * It's the recommended place to pull data from remote endpoint, c.g., API Gateway REST APIs.
   */
  componentDidMount() {
    getTopicPreference(this.props.listId, this.props.userInternalId, this.props.notificationId)
      .then((response) => this.setUpUserData(response))
      .catch((error) => this.handleError(error));
  }

  render() {
    if (this.state.isLoading) return <LoadingComponent />;
    if (this.state.error) return <ErrorComponent error={this.state.error} />;

    return (
      <Grid style={{ marginTop: "3em", marginBottom: "3em" }}>
        <Grid.Column width={2}></Grid.Column>
        <Grid.Column width={9}>
          {this.state.updateCompleted && <UpdateSuccessMessage />}
          <Header as="h2">Subscription preferences</Header>
          <Header as="h3">Email address: {this.state.userId}</Header>
          <SubscriptionInfo prefs={this.state.prefs} onChange={this.handleSubscriptionChange} />
          <Divider section hidden={this.state.prefs.length === 0} />
          <Checkbox
            data-testid="unsubscribeAllCheckbox"
            label={{
              children: (
                <Header as="h5">
                  Unsubscribe from all
                  <Header.Subheader style={{ marginTop: "0.5em" }}>
                    You will still receive important transactional and billing-related emails.
                  </Header.Subheader>
                </Header>
              ),
            }}
            checked={this.state.unsubscribeAll}
            onChange={this.handleUnsubscribeAll}
          />
          <Divider section hidden={this.state.prefs.length === 0} />
          <Button onClick={this.handleUpdateEvent} disabled={this.state.updateButtonDisabled} primary>
            Update
          </Button>
        </Grid.Column>
      </Grid>
    );
  }

  /**
   * Sets the end-user's topic preference data retrieved from the GET API.
   */
  setUpUserData(response) {
    const topics = response["Topics"];
    const topicPreferenceMap = buildTopicPreferenceMap(response["TopicPreferenceInfo"]["TopicPreferences"]);
    const unsubscribeAll = response["TopicPreferenceInfo"]["Unsubscribed"];

    let prefs = [];
    if (Array.isArray(topics) && topics.length) {
      topics.forEach((topic, index) => {
        const subscribed = isSubscribed(topic, topicPreferenceMap, unsubscribeAll);
        prefs.push({
          id: index,
          name: topic["Name"],
          displayName: topic["DisplayName"],
          description: topic["Description"],
          subscribed: subscribed,
          originalSubscribed: subscribed,
        });
      });
    }

    this.setState({
      isLoading: false,
      prefs: prefs,
      userId: response["UserId"],
      unsubscribeAll: unsubscribeAll,
      originalUnsubscribeAll: unsubscribeAll,
    });
  }

  /**
   * Handler for setting the error state.
   */
  handleError(error) {
    this.setState({
      error: error,
      isLoading: false,
    });
  }

  isTopicPreferenceChanged(updatedPrefs, updatedUnsubscribeAll) {
    let changed = this.state.originalUnsubscribeAll !== updatedUnsubscribeAll;
    updatedPrefs.forEach((pref) => {
      changed = changed || pref.subscribed !== pref.originalSubscribed;
    });
    return changed;
  }

  /**
   * Handler for a single topic subscription.
   */
  handleSubscriptionChange = (event, data) => {
    let unsubscribeAll = true;
    let prefs = this.state.prefs;
    prefs.forEach((pref) => {
      if (pref.id === data.id) {
        pref.subscribed = data.checked;
      }
      unsubscribeAll = unsubscribeAll && !pref.subscribed;
    });
    this.setState({
      prefs: prefs,
      unsubscribeAll: unsubscribeAll,
      updateButtonDisabled: !this.isTopicPreferenceChanged(prefs, unsubscribeAll),
    });
  };

  /**
   * Handler for unsubscribe all.
   */
  handleUnsubscribeAll = (event, data) => {
    let prefs = this.state.prefs;
    prefs.forEach((pref) => {
      pref.subscribed = pref.subscribed && !data.checked;
    });
    this.setState({
      prefs: prefs,
      unsubscribeAll: data.checked,
      updateButtonDisabled: !this.isTopicPreferenceChanged(prefs, data.checked),
    });
  };

  /**
   * Handler for updating end-user's topic preferences.
   */
  handleUpdateEvent = (event, data) => {
    let prefs = this.state.prefs;
    let updateTopicPreferences = [];

    if (this.state.unsubscribeAll) {
      // always include every topic and set the status to OPT_OUT if the unsubscribe all checkbox is checked
      prefs.forEach((pref) => {
        updateTopicPreferences.push({
          TopicName: pref.name,
          SubscriptionStatus: "OPT_OUT",
        });
        pref.originalSubscribed = false;
      });
    } else {
      // updates the topic preference regardless whether the preference is truly updated or not
      prefs.forEach((pref) => {
        updateTopicPreferences.push({
          TopicName: pref.name,
          SubscriptionStatus: pref.subscribed ? "OPT_IN" : "OPT_OUT",
        });
        pref.originalSubscribed = pref.subscribed;
      });
    }

    // Clear the success message if exists
    if (this.state.updateCompleted) {
      this.setState({
        updateCompleted: false,
        updateButtonDisabled: true,
      });
    }

    updateTopicPreference(
      this.props.listId,
      this.props.userInternalId,
      this.props.notificationId,
      this.state.unsubscribeAll,
      updateTopicPreferences
    )
      .then((response) =>
        this.setState({
          prefs: prefs,
          originalUnsubscribeAll: this.state.unsubscribeAll,
          updateCompleted: true,
          updateButtonDisabled: true,
        })
      )
      .then(() =>
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        })
      )
      .catch((error) => this.handleError(error));
  };
}

export default MainPanel;
